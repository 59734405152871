import React from 'react'
import Galleries from '../../components/galleries/galleries.js'
import Layout from '../../layouts/index.js'

const array = [
  '008198',
  '008211',
  '008215',
  '008226',
  '008233',
  '008235',
  '008249',
  '008257',
  '008272',
  '008275',
  '008280',
  '008283',
  '008291',
  '008294',
]

const images = array.map((image) => {
  return (
    <figure>
      <img
        alt=""
        key={image}
        src={require('../../images/gallery/galapagos/' + image + '.jpg')}
      />
    </figure>
  )
})

const Galapagos = () => (
  <Layout>
    <section>
      <h1>Galapagos</h1>
      {images}
      <Galleries />
    </section>
  </Layout>
)

export default Galapagos
